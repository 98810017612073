import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import VueSocketIO from 'vue-3-socket.io'
import axios from 'axios'

import Notifications from '@kyvg/vue3-notification'

let baseUrl = document.location.href.split(':')[1]
if (!baseUrl.endsWith('/')) baseUrl += '/'

const axiosInstance = axios.create({
  baseURL: baseUrl
})

const socketInstance = new VueSocketIO({
  connection: baseUrl,
  withCredentials: false,
})

loadFonts()

const app = createApp(App)
  .use(vuetify)
  .use(socketInstance)
  .use(Notifications)

app.config.globalProperties.$axios = { ...axiosInstance }

app.mount('#app')