<template>
  <v-app>
    <v-main>
      <v-dialog v-model="mostraImpostazioni" width="400">
        <v-card>
          <v-card-title>
            Impostazioni
          </v-card-title>
          <v-card-text>

            <v-autocomplete v-model="options.evento" :items="eventi" item-title="nome" item-value="codice" />

            <v-combobox v-model="options.categorie" :items="categorie" item-title="nome" item-value="codice" multiple />

            <v-checkbox v-model="options.solaLettura" label="Vista semplificata" />

          </v-card-text>
        </v-card>
      </v-dialog>

      <v-btn @click="mostraImpostazioni = true" icon="mdi-cog" color="indigo"
        style="position: absolute; z-index: 10; right: 10px; top: 15px; height: 35px; width: 35px;"></v-btn>

      <Dashboard :evento="options.evento" :categorie="options.categorie" :solaLettura="options.solaLettura" />
    </v-main>

    <notifications position="bottom left" />
  </v-app>
</template>

<script>
import Dashboard from './components/Dashboard.vue'

export default {
  name: 'App',

  components: {
    Dashboard,
  },

  data: () => ({
    eventi: [],
    categorie: [],

    options: {},
    mostraImpostazioni: false
  }),

  mounted: function () {
    this.load()
  },

  watch: {
    options: {
      handler: function (v) {
        this.saveOptions(v)
      },
      deep: true
    }
  },

  methods: {
    load() {
      const _ = this

      let savedOptions = localStorage.getItem('options')

      if (savedOptions) savedOptions = JSON.parse(savedOptions)
      else savedOptions = {}

      _.options = { ...savedOptions }

      _.$axios.get('listEventi')
        .then((res) => {
          _.eventi = res.data.data
        })

      _.$axios.get('listCategorie')
        .then((res) => {
          _.categorie = res.data.data
        })
    },

    saveOptions() {
      const _ = this

      localStorage.setItem('options', JSON.stringify(_.options))
    }
  }
}
</script>
<style>
body {
  touch-action: none;
}
</style>