<template>
  <v-container fluid>

    <v-dialog v-model="mostraResto" width="600" persistent>
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          Calcolo resto

          <v-btn variant="text" icon @click="mostraResto = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>

          <v-row class="text-center text-h6 d-flex justify-center">
            <v-col cols="3" style="color: #2196f3;">Somma da Cliente</v-col>
            <v-col cols="1"></v-col>
            <v-col cols="3">Totale da Pagare</v-col>
            <v-col cols="1"></v-col>
            <v-col cols="3" :class="pagamento - totaleCarrello() < 0 ? 'text-red' : 'text-green'">Resto dovuto</v-col>

            <v-col cols="3" style="border: 3px #2196f3 solid;" class="text-blue">
              {{ pagamento === '' ? 0 : pagamento }}
            </v-col>
            <v-col cols="1">
              -
            </v-col>
            <v-col cols="3">
              {{ totaleCarrello().toFixed(2) }} €
            </v-col>
            <v-col cols="1">
              =
            </v-col>
            <v-col cols="3" :class="pagamento - totaleCarrello() < 0 ? 'text-red' : 'text-green'">
              {{ (pagamento - totaleCarrello()).toFixed(2) }} €
            </v-col>
          </v-row>

          <v-row class="mt-5 d-flex justify-center align-center" dense>
            <v-col cols="4" v-for="(x, i) in (new Array(9))" :key="i">
              <v-btn @click="pagamento += (i + 1)" block color="blue" size="x-large">
                {{ i + 1 }}
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn @click="pagamento = pagamento.slice(0, -1);" color="red" block size="x-large">
                DEL
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn @click="pagamento += '0'" color="blue" block size="x-large">
                0
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn @click="pagamento += '.'" color="blue-darken-4" block size="x-large">
                .
              </v-btn>
            </v-col>
            <v-col cols="12" class="mt-3">
              <v-btn @click="chiudiCarrello()" color="green" :loading="loadTransazione" block size="large">Paga</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="8">
        <v-btn v-if="!solaLettura" @click="mostraVenduti = !mostraVenduti" text hide-details class="mb-5" color="grey">
          Vista {{ mostraVenduti ? 'Giacenza' : 'Venduti' }}
        </v-btn>

        <v-row class="pa-0 align-content-start" style="height: 85vh; overflow-y: auto;" fluid>

          <v-col cols="12" v-for="c of categorie" :key="c.codice" class="d-flex flex-wrap align-content-start">
            <v-container class="ml-2 pa-0 mb-3 text-h5 font-weight-bold" fluid>
              {{ c.nome }}
            </v-container>

            <div v-for="a of articoli.filter(a => a.categoria === c.codice)"
              :class="['bg-' + a.colore, 'ma-2 pa-3 d-flex flex-column justify-space-between text-white']"
              style="height: 100px; width: 170px;" :key="a" @click="aggiungiCarrello(a)">

              <span class="text-h6 font-weight-semibold">{{ a.nome }}</span>
              <div class="d-flex justify-space-between align-end text-body-1">
                <span class="font-weight-regular" v-if="!solaLettura">{{ fixPrezzo(a.prezzo) }}€</span>
                <span class="font-weight-light">
                  {{ qtaArticolo(a.codice) }}
                  {{ (mostraVenduti ? '+' : '/') }}
                  {{ (mostraVenduti ? a.qtaVenduta || 0 : a.giacenza || 0) }}
                </span>
              </div>
            </div>
          </v-col>

        </v-row>

      </v-col>

      <v-col v-if="!solaLettura" cols="4"
        style="border-color: black; border-style: solid; border-width: 0px 0px 0px 2px;">

        <v-row>
          <v-col class="d-flex text-h5 font-weight-bold justify-space-between" fluid cols="12">
            <span>Tot = {{ totaleCarrello()?.toFixed(2) }}€</span>

            <v-btn class="mr-10" @click="mostraResto = true" :disabled="carrello.length === 0"
              color="green">Pagamento</v-btn>
          </v-col>

          <v-col cols="12" v-for="(a, index) in carrello" :key="a.codArt">
            <v-row :class="['align-center justify-center', index % 2 ? 'bg-grey-lighten-3' : '']" style="height: 70px;">
              <v-col cols="2">
                <v-btn style="height: 30px; width: 30px;" icon="mdi-minus" @click="rimuoviCarrello(a)" color="red" />
              </v-col>
              <v-col cols="8" style="margin-left: -20px;">
                {{ a.nome }} x {{ a.qta }}
              </v-col>
              <v-col cols="2">
                <v-btn style="height: 40px; width: 40px;" icon="mdi-trash-can" @click="rimuoviCarrello(a, a.qta)"
                  color="red" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  // eslint-disable-next-line
  name: 'Dashboard',

  props: {
    evento: {
      type: Number,
      required: true
    },
    categorie: {
      type: Array,
      required: true
    },
    solaLettura: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    evento: {
      handler: function (v) {
        if (v && v > 0) this.load()
      }
    },
    categorie: {
      handler: function (v) {
        if (v && v.length > 0) this.load()
      },
      deep: true
    }
  },

  data: () => ({
    articoli: [],
    carrello: [],

    loadTransazione: false,

    mostraVenduti: false,

    pagamento: '',
    mostraResto: false
  }),

  mounted: function () {
    this.load()
  },

  sockets: {
    refresh: function (args) {
      const _ = this

      if (args && args.evento && args.evento !== _.evento) return
      console.log('Updated database', args)

      _.load(false)
    }
  },

  methods: {
    load(empty = true) {
      const _ = this

      if (!_.categorie || _.categorie.length === 0 || !_.evento) return

      if (empty) _.carrello = []

      const payload = {
        codCat: _.categorie.map(c => c.codice),
        codEve: [_.evento]
      }

      _.$axios.post('listArticoli', payload)
        .then((res) => {
          _.articoli = res.data.data
        })
    },

    totaleCarrello() {
      const _ = this

      let tot = 0
      for (const item of _.carrello)
        tot += item.prezzo * (item.qta || 1)

      return tot
    },

    aggiungiCarrello(item, qta = 1) {
      const _ = this

      let cur = _.carrello.find(i => i.codice === item.codice)

      if (cur)
        cur.qta += qta
      else
        _.carrello.push({ ...item, qta })

      _.carrello = _.carrello.sort((x, y) => {
        return x.nome > y.nome
      })
    },

    rimuoviCarrello(item, qta = 1) {
      const _ = this

      let cur = _.carrello.find(i => i.codice === item.codice)

      if (cur) {
        if (cur.qta - qta <= 0) {
          let i = _.carrello.indexOf(cur)
          _.carrello.splice(i, 1)

          return
        }

        cur.qta -= qta
      }
    },

    chiudiCarrello() {
      const _ = this

      _.loadTransazione = true

      const arts = []

      const carrello = [..._.carrello]

      for (const x of carrello) {
        if (x.args?.multiple) {
          for (const y of x.args.multiple) {
            arts.push({
              codArt: y.codArt,
              qtaTra: y.qtaArt,
              nonFattTra: 1 // Non fatturabile perché se no è incasso doppio con anche menu
            })
          }

          // continue // HOTFIX: 26mag24 - vogliamo contare anche i menu venduti oltre ai singoli prodotti
        }

        arts.push({
          codArt: x.codice,
          qtaTra: x.qta
        })
      }

      const payload = {
        codEve: _.evento,
        codCassa: "CASSA_MAIN",
        arts: arts,
        artsRaw: carrello
      }

      _.$axios.post('newTransazioni', payload)
        .then(() => {
          _.loadTransazione = false

          _.carrello = []
          _.mostraResto = false
          _.pagamento = ''

          this.$notify({ type: "success", text: "Operazione effettuata." })
        })
        .catch(() => {
          _.loadTransazione = false

          this.$notify({ type: "error", text: "Operazione fallita." })
        })
    },

    qtaArticolo(codice) {
      const _ = this

      return _.carrello.find(i => i.codice === codice)?.qta || 0
    },

    fixPrezzo(prezzo) {

      prezzo = parseFloat(prezzo)
      return prezzo.toFixed(2)
    },
  }

}
</script>